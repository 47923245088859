import { TradeStatus } from '@alpha/fx-dtos';

export const mapFxTradeStatusDisplay = (
  tradeStatus: TradeStatus,
): {variant:'success' | 'error' | 'orange' | 'default' | 'info' | 'warning' | undefined, text:
    string} => {
  switch (tradeStatus) {
    case TradeStatus.ACTIVE:
      return { variant: 'info', text: 'OPEN' };
    case TradeStatus.AWAITING_SETTLEMENT:
      return { variant: 'info', text: 'AWAITING SETTLEMENT' };
    case TradeStatus.CANCELLED:
      return { variant: 'error', text: 'CLOSED OUT' };
    case TradeStatus.COMPLETED:
      return { variant: 'success', text: 'SETTLED' };
    case TradeStatus.INVALID:
      return { variant: 'error', text: 'INVALID' };
    case TradeStatus.VALIDATED:
      return { variant: 'info', text: 'VALIDATED' };
    case TradeStatus.SUBMITTED:
    case TradeStatus.VERIFIED:
      return { variant: 'info', text: 'AWAITING APPROVAL' };
    case TradeStatus.REJECTED:
      return { variant: 'error', text: 'REJECTED' };
    default:
      return { variant: 'warning', text: '-' };
  }
};

export default mapFxTradeStatusDisplay;
