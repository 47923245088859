const getCurrentWeekdayAndHour = (): {
  currentHour: number;
  currentWeekday: string;
} => {
  const currentDate = new Date(Date.now());
  const currentHour = currentDate.toLocaleString('en-GB', {
    timeZone: 'Europe/London',
    hour: 'numeric',
  });
  const currentWeekday = currentDate.toLocaleString('en-GB', {
    timeZone: 'Europe/London',
    weekday: 'long',
  });

  return {
    currentHour: parseInt(currentHour, 10),
    currentWeekday,
  };
};

const isFxMarketClosed = (): boolean => {
  const { currentHour, currentWeekday } = getCurrentWeekdayAndHour();

  return (
    (currentWeekday === 'Friday' && currentHour >= 20)
    || (currentWeekday === 'Saturday')
    || (currentWeekday === 'Sunday' && currentHour < 23)
  );
};

export default isFxMarketClosed;
