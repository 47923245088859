import React from 'react';
import t from 'utils/translationHelper';

import { BeneficiaryDto, BeneficiaryStatus } from '@alpha/bene-dtos';
import { Button, Typography } from '@alpha/ui-lib/ui/external';
import { Stat } from '@alpha/ui-lib/ui/Stat';
import { TStatusVariants } from '@alpha/ui-lib/ui/Status';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faShareAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAuthorization from '../../../../../hooks/useAuthorization';
import { UserRole } from '../../../../../models/user';
import { ModalType } from '../../Body';
import { ISelectedBeneficiary } from '../BeneInfoDrawer';

import useStyles from './Header.styles';

interface IProps {
  selectedBeneficiary: ISelectedBeneficiary;
  canShareBeneficiary: boolean;
  handleBeneficiaryShareButtonClick: (bene?: BeneficiaryDto) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>
}

const Header: React.FC<IProps> = ({
  selectedBeneficiary,
  canShareBeneficiary,
  handleBeneficiaryShareButtonClick,
  setModalOpen,
}: IProps) => {
  const classes = useStyles();

  const { authorized: canShareBeneficiaryOwn } = useAuthorization([[UserRole.BENEFICAIRY_APPROVER_OWN]]);
  const { authorized: canDeleteBeneficiary } = useAuthorization(
    [[UserRole.BENEFICIARY_APPROVER, UserRole.BENEFICAIRY_APPROVER_OWN]],
    selectedBeneficiary?.beneficiary?.uploadedById,
  );

  type StatusDisplay = { statusText: string, statusVariant: TStatusVariants };
  const getStatus = (): StatusDisplay => {
    if (!selectedBeneficiary?.beneficiary) return { statusText: 'n/a', statusVariant: 'info' };

    if (selectedBeneficiary?.beneficiary.status === BeneficiaryStatus.CLIENT_APPROVED) {
      return { statusText: t('approved'), statusVariant: 'success' };
    } if (selectedBeneficiary?.beneficiary.status === BeneficiaryStatus.CLIENT_REJECTED
      || selectedBeneficiary?.beneficiary.status === BeneficiaryStatus.ALPHA_REJECTED) {
      return { statusText: t('rejected'), statusVariant: 'error' };
    }

    return { statusText: t('pending_approval~'), statusVariant: 'info' };
  };

  const status = getStatus();

  const showShareButton = canShareBeneficiary && canShareBeneficiaryOwn;

  if (!selectedBeneficiary?.beneficiary) return <></>;

  return (
    <div className={classes.header}>
      <div className={classes.headerText} data-testid="info-bene">
        <b className={classes.title}>
          {selectedBeneficiary?.beneficiary.friendlyName || selectedBeneficiary.beneficiary.name}
        </b>
        <Typography className={classes.subtitle}>
          {
            selectedBeneficiary.beneficiary.name || selectedBeneficiary?.beneficiary.friendlyName
          }
        </Typography>
      </div>
      <div className={classes.headerStats} data-testid="status-bene">
        <Stat
          title="Status"
          value={status.statusText}
          variant="status"
          statusVariant={status.statusVariant}
        />
        <StyledDivider vertical />
        <div className={classes.countryCurrency}>
          <Stat
            variant="country"
            value={
              (selectedBeneficiary?.beneficiary.bankCountryCode)
              || '-'
            }
            title={t('country')}
          />
          <Stat
            variant="currency"
            value={
              (selectedBeneficiary?.beneficiary.currencyCode)
              || '-'
            }
            title={t('currency')}
          />
        </div>
        {
          showShareButton && (
            <APTooltip title={t('share_beneficiary_with_other_entities')}>
              <Button
                className={classes.button}
                onClick={
                  () => handleBeneficiaryShareButtonClick(
                    selectedBeneficiary.beneficiary,
                  )
                }
                data-testid="share-button-drawer"
              >
                <FontAwesomeIcon size="lg" icon={faShareAlt as IconProp} />
              </Button>
            </APTooltip>
          )
        }
        {
          selectedBeneficiary?.approved && canDeleteBeneficiary
            ? (
              <APTooltip title={t('delete_beneficiary')}>
                <Button
                  data-testid="delete-beneficiary"
                  onClick={() => setModalOpen('delete')}
                  className={classes.deleteButton}
                >
                  <FontAwesomeIcon size="lg" icon={faTrashAlt as IconProp} />
                </Button>
              </APTooltip>
            )
            : null
        }
      </div>
    </div>
  );
};

export default Header;
