import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ typography }) => ({
  formContainer: {
    '& .MuiTextField-root > label': {
      color: '#212529',
      fontSize: '19px',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '25px',
    },
    '& .datePicker': {
      width: '100%',
    },
    '&  .MuiFormControl-fullWidth': {
      width: '100% !important',
    },
    '& #amount': {
      width: '350px',
    },
  },
  alert: {
    width: '100%',
    marginTop: '10px',
    fontColor: '#3E8CC11A',
  },
  tradeDetails: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '14px',
    fontWeight: typography.fontWeightSemiBold,
    padding: '5px 0px 10px 0px',
  },
  drawdownHeading: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '14px',
    fontWeight: typography.fontWeightSemiBold,
    padding: '15px 0px 30px 0px',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropdown: {
    '& .MuiSelect-root': {
      backgroundColor: '#f7f7f7',
      height: '44px',
      width: '73px',
      borderRadius: '5px',
      padding: '0 16px',
      paddingRight: '20px',
      marginBottom: '6px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      right: '7px',
    },
  },
  ccyContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  defaultBeneficiaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '50px',
    backgroundColor: '#fafafa',
    justifyContent: 'space-between',
    padding: '16px',
  },
  status: {
    width: 'hug(124px)',
    height: '30px',
  },
}), { name: 'DrawdownStyles' });

export default useStyles;
