import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ typography }) => ({
    container: {
      textAlign: 'left',
    },
    modalWrapper: {
      border: 'none',
      padding: '28px 0px 28px 0px',
      maxWidth: '620px',
      width: '80%',
    },
    headerWrapper: {
      padding: '0px 32px',
    },

    title: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    header: {
      fontSize: 22,
      fontWeight: typography.fontWeightSemiBold,
    },
    subHeader: {
      marginBottom: '32px',
      marginTop: '32px',
      fontSize: '13px',
      color: '#212529BF',
    },
    divider: {
      marginTop: '32px',
      marginBottom: '16px',
      backgroundColor: '#F7F7F7',
    },
    footerWrapper: {
      padding: '0px 32px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      '& > button:last-child': {
        marginLeft: 8,
      },
    },
  }),
  { name: 'BeneShareNotAvailableModal' },
);

export default useStyles;
