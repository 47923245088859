import React from 'react';
import APModal from 'components/Modals/APModal/APModal';
import { Trans } from 'react-i18next';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Divider, Typography } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './index.styles';

interface IProps {
  modalOpen: boolean;
  handleCloseModal: () => void;
  handleAcknowledgeClick: () => void;
}

const BeneShareNotAvailableModal: React.FC<IProps> = (props: IProps) => {
  const {
    modalOpen,
    handleCloseModal,
    handleAcknowledgeClick,
  } = props;
  const classes = useStyles();

  return (
    <APModal.Container
      open={modalOpen}
      className={classes.modalWrapper}
      disableBackdropClick
    >
      <>
        <APModal.Header
          onClose={handleCloseModal}
        >
          <APModal.Wrapper
            className={classes.headerWrapper}
          >
            <div className={classes.title}>
              <Typography className={classes.header}>
                <FontAwesomeIcon
                  icon={faInfoCircle as IconProp}
                  size="sm"
                  color="#3E8CC1"
                  style={{ marginRight: '8px' }}
                />
                {' '}
                {t('Sharing a UK Beneficiary')}
              </Typography>
            </div>
          </APModal.Wrapper>
        </APModal.Header>
        <APModal.Wrapper>
          <div className={classes.container}>
            <Typography className={classes.subHeader}>
              <Trans
                components={{ strong: <strong /> }}
              >
                cannot_share_bene_message
              </Trans>
            </Typography>
          </div>
        </APModal.Wrapper>
        <Divider className={classes.divider} />
        <APModal.Wrapper
          className={classes.footerWrapper}
        >
          <div className={classes.buttons}>
            <ActionButton
              variant="outlined"
              size="medium"
              id="acknowledgekButton"
              onClick={handleAcknowledgeClick}
              testId="acknowledge-button"
              fullWidth
            >
              {t('acknowledge')}
            </ActionButton>
          </div>
        </APModal.Wrapper>
      </>
    </APModal.Container>
  );
};

export default BeneShareNotAvailableModal;
