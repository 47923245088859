import moment, { Moment } from 'moment-timezone';

import { PaymentDateOptionsDto } from '@alpha/payments-dtos';

const calculateDisablePaymentDates = (_date: Moment, dateOptions?: PaymentDateOptionsDto): boolean => {
  if (dateOptions) {
    const formattedDate = _date.tz('Europe/London').format('YYYY-MM-DD');
    const todayDate = moment.tz('Europe/London').format('YYYY-MM-DD');

    return (
      moment(formattedDate).isBefore(todayDate)
      || moment(formattedDate).isAfter(dateOptions.latestDate)
      || dateOptions.holidays.some((holiday) => moment(holiday.date).tz('Europe/London').format('YYYY-MM-DD') === formattedDate)
    );
  }
  return false;
};

export default calculateDisablePaymentDates;
