import {
  Dispatch, SetStateAction, useEffect, useRef,
  useState,
} from 'react';
import { FeatureFlagName } from 'services/Config/config.service';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { BeneficiaryDto } from '@alpha/bene-dtos';

import { TBeneficiaryApproved, TBeneficiaryPending } from '../models/beneficiaries';
import { UserRole } from '../models/user';
import BeneficiariesService from '../services/Beneficiaries/beneficiaries.service';

import useAlphaSnackbar from './useAlphaSnackbar';
import useAuthorization from './useAuthorization';
import useGetFeatureFlags from './useGetFeatureFlags';

const useShareBeneficiaryDrawer = (setSelectedBeneficiary: Dispatch<SetStateAction<{
  beneficiary: TBeneficiaryApproved | TBeneficiaryPending;
  approved: boolean;

} | undefined>>) => {
  const { isFlagActive } = useGetFeatureFlags();
  const [accountBeneId, setAccountBeneId] = useState('');
  const [openSharingNotAvailable, setOpenSharingNotAvailable] = useState(false);
  const availableAccounts = useRef<AccountDto[]>([]);

  const requiresCOPCheck = (
    bene:BeneficiaryDto,
  ) => {
    if (isFlagActive(FeatureFlagName.COP)) {
      return ['GB', 'IM', 'GI', 'GG', 'FK', 'JE'].includes(bene?.bankCountryCode) && bene?.currencyCode === 'GBP';
    }
    return false;
  };

  const sb = useAlphaSnackbar();
  const canShareBeneficiary = useAuthorization([[UserRole.BENEFICIARY_INPUTTER]]).authorized;

  useEffect(() => {
    if (canShareBeneficiary) {
      getShareAccounts();
    }
  }, [canShareBeneficiary]);

  async function getShareAccounts() {
    try {
      const response = await BeneficiariesService.getAvailableAccountsToShareBeneficiary();
      if (response) availableAccounts.current = response;
    } catch (e) {
      sb.trigger(e.response?.data?.error || e.message || t('something_went_wrong_retrieving_your_accounts'));
    }
  }

  const handleBeneficiaryShareButtonClick = (bene?: BeneficiaryDto) => {
    if (bene) {
      if (!requiresCOPCheck(bene)) {
        setOpenSharingNotAvailable(false);
        setAccountBeneId(bene.id); // this opens the drawer
        setSelectedBeneficiary(undefined);
      } else {
        setOpenSharingNotAvailable(true);
      }
    } else {
      setAccountBeneId(''); // this closes the drawer
    }
  };

  return {
    availableAccounts: availableAccounts.current,
    accountBeneId,
    handleBeneficiaryShareButtonClick,
    openSharingNotAvailable,
    setOpenSharingNotAvailable,
  };
};

export default useShareBeneficiaryDrawer;
